.sgbs-stepper {
  padding-left: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.sgbs-stepper-step {
  flex: 2;
  position: relative;

  &.inactive {
    color: $sgbs-progress-molecule-inactive-color;

    .sgbs-stepper-label {
      color: $sgbs-progress-molecule-inactive-label-color !important;
    }
  }

  &.blocked {
    .sgbs-stepper-step-inner::after {
      color: $sgbs-progress-molecule-inactive-color;
    }

    &.muted {
      .sgbs-stepper-step-inner::before {
        color: $primary;
      }
    }
  }
}

.sgbs-stepper-step-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::before,
  &::after {
    content: '';
    flex: 1;
    height: $sgbs-progress-molecule-item-border-width;
    background: currentColor;
  }
}

.sgbs-stepper-label {
  @include variable-weight($sgbs-progress-molecule-label-font-weight);
  margin-top: $sgbs-progress-molecule-item-size/2;
  font-size: $sgbs-progress-molecule-label-font-size;
  display: block;

  .sgbs-stepper:not(.sgbs-stepper-vertical) & {
    text-align: center;
  }
}

.sgbs-stepper-indicator {
  width: $sgbs-progress-molecule-item-size;
  height: $sgbs-progress-molecule-item-size;
  border-radius: 50%;
  border: $sgbs-progress-molecule-item-border-width solid currentColor;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  .fill & {
    background: currentColor;
  }
}

.sgbs-stepper-no-indicator {
  height: $sgbs-progress-molecule-item-size;
  text-align: center;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Exceptions */

.sgbs-stepper-step:not(.current) .icon {
  color: $sgbs-progress-icon-color;
  height: 0.8rem;
  line-height: 0.8rem;
}

button.sgbs-stepper-indicator {
  cursor: pointer;
  padding: 0;

  @each $color, $value in $theme-colors {
    .text-#{$color} & {
      color: $value;
    }
  }
}

.sgbs-stepper .sgbs-stepper-step {
  &:first-child {
    flex: 1;
    .sgbs-stepper-step-inner::before {
      display: none;
    }
    .sgbs-stepper-label {
      text-align: left;
    }
  }

  &:last-child {
    flex: 1;
    .sgbs-stepper-step-inner::after {
      display: none;
    }
    .sgbs-stepper-label {
      text-align: right;
    }
  }
}

/* Vertical */
.sgbs-stepper-vertical {
  height: 100%;
  flex-direction: column;

  .sgbs-stepper-step-inner {
    flex-direction: column;
    height: 100%;
  }

  .sgbs-stepper-step-inner {
    flex-direction: column;
    height: 100%;

    &::before,
    &::after {
      content: '';
      width: $sgbs-progress-molecule-item-border-width;
      height: 100%;
    }
  }

  .sgbs-stepper-label {
    position: absolute;
    margin-top: 0;
    top: 50%;
    transform: translate(0, -0.35 * $sgbs-progress-molecule-item-size);
    left: 2 * $sgbs-progress-molecule-item-size;
    white-space: nowrap;
  }

  .sgbs-stepper-step {
    &:first-child {
      .sgbs-stepper-label {
        top: 0;
        transform: none;
      }
    }
    &:last-child {
      .sgbs-stepper-label {
        bottom: 0;
        top: auto;
        transform: none;
      }
    }
  }
}
