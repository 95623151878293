@import 'bootstrap/scss/pagination';

.pagination {
  @include pagination-size(
    $pagination-padding-y,
    $pagination-padding-x,
    $sgbs-pagination-font-size-base,
    $pagination-line-height,
    $sgbs-pagination-border-radius
  );

  // Disabled item
  .page-item.disabled {
    opacity: $sgbs-pagination-disabled-item-opacity;

    .page-link {
      background: transparent;
    }
  }
}

.page-link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ($btn-line-height * $font-size-base) + ($btn-padding-y * 2) +
    (2 * $btn-border-width);

  &:focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
    background: $pagination-hover-bg;
  }
}

.pagination-borderless {
  .page-item.active .page-link {
    color: $pagination-color;
    border-color: transparent;
    background: rgba($pagination-hover-bg, $sgbs-page-link-opacity);
  }

  .page-link {
    margin-left: 0;
    color: $pagination-color;
    border-color: transparent;

    &:focus,
    &:hover {
      background: rgba($pagination-hover-bg, $sgbs-page-link-hover-opacity);
    }

    &:active {
      background: rgba($pagination-hover-bg, $sgbs-page-link-opacity);
    }
  }

  .page-item.disabled .page-link {
    border-color: transparent;
  }
}

.pagination-outline {
  @include sgbs-pagelink-active(
    transparent,
    $pagination-border-color,
    $pagination-color
  );
}

// Alternate styles
@each $color, $value in $theme-colors {
  .pagination-outline-#{$color} {
    @include sgbs-pagelink-active(transparent, $value, $value);
    .page-link {
      border-color: transparent;
      background: transparent;
    }
  }
  .pagination-#{$color} {
    &:not(.pagination-borderless) .page-item {
      .page-link {
        border-color: $value;
      }
    }
    &.pagination-borderless .page-item {
      &.active {
        .page-link {
          border-color: $value;
        }
      }
      .page-link:hover,
      .page-link:focus,
      .page-link:active {
        border-color: $value;
      }
    }

    @include sgbs-pagelink-active($value, $value, $primary-alt);
  }
}

//
// Sizing
//

// Icon inside page item should have a height to extend button when icon is not followed by text
// When there is text, the line-height does the job
.pagination-sm {
  @include pagination-size(
    $pagination-padding-y-sm,
    $pagination-padding-x-sm,
    $sgbs-pagination-font-size-sm,
    $sgbs-pagination-line-height-sm,
    $sgbs-pagination-border-radius
  );

  .page-link {
    min-height: ($btn-line-height-sm * $font-size-sm) +
      ($pagination-padding-y-sm * 2) + (2 * $btn-border-width);
  }
}

.pagination-lg {
  @include pagination-size(
    $pagination-padding-y-lg,
    $pagination-padding-x-lg,
    $sgbs-pagination-font-size-lg,
    $sgbs-pagination-line-height-lg,
    $sgbs-pagination-border-radius
  );

  .page-link {
    min-height: ($btn-line-height-lg * $sgbs-btn-font-size-lg) +
      ($pagination-padding-y-lg * 2) + (2 * $btn-border-width);
  }
}

.pagination-xl {
  @include pagination-size(
    $sgbs-pagination-padding-y-xl,
    $sgbs-pagination-padding-x-xl,
    $sgbs-pagination-font-size-xl,
    $sgbs-pagination-line-height-xl,
    $sgbs-pagination-border-radius
  );

  .page-link {
    min-height: ($sgbs-btn-line-height-xl * $sgbs-btn-font-size-xl) +
      ($sgbs-btn-padding-y-xl * 2) + (2 * $btn-border-width);
  }
}
