//
// Base styles
//

@import 'bootstrap/scss/card';

//
// SIZING
//
// default is 1rem, set in Bootstrap variables

$card-paddings: (
  md: 1.5rem,
  xxl: 2rem
);

@each $breakpoint, $value in $card-paddings {
  @include media-breakpoint-up($breakpoint) {
    .card-body {
      padding: $value;
    }

    .card-header {
      padding: $value;
    }

    .card-footer {
      padding: $value;
    }
  }
}

.card-header + .card-body {
  padding-top: 0;
}

.card-body + .card-footer {
  padding-top: 0;
}

@each $color, $value in $theme-colors {
  .card.card-#{$color} {
    @include card-states($value);
  }
}

//
// STATES
//

.card-bordered {
  @include card-border($sgbs-card-border-width, $card-border-color);

  &.active {
    @include card-border($sgbs-card-bordering-width, $primary);
  }
}

.card-bordering {
  @include card-state-bordering();
}

.card-raised {
  box-shadow: $box-shadow-sm;

  &.active {
    box-shadow: $box-shadow;
  }
}

.card-filled {
  background-color: rgba($primary, $sgbs-card-filled-bg-opacity);

  &.active {
    background-color: rgba($primary, $sgbs-card-active-bg-opacity);
  }
}

.card-filling {
  @include card-state-filling();
}

//
// Raising card
// The card will raise/elevate toward the user when hover/focus/active
//

.card-raising {
  @include card-state-raising();
}

// Colored states

@each $color, $value in $theme-colors {
  .card.card-bordering-#{$color} {
    @include card-state-bordering($value);
  }

  .card.card-filling-#{$color} {
    @include card-state-filling($value);
  }
}

//
// Card image
//

.card-img-overlay {
  background-color: $sgbs-card-img-overlay-bg;
}

//
// Newsstand
//
.newsstand .card-body::before {
  content: '';
  background: $red-socgen;
  width: 1rem;
  height: 1rem;
  display: block;
  margin-bottom: 1rem;
}

//
// Hyper Card
// Mix of hyperlink and a card
//

.card[href] {
  color: inherit;

  @include hover {
    text-decoration: none;
  }
}

//
// Optional textual caps
//

.card-header {
  // Less border more space
  // you can still have a border  by using `.border-bottom`
  border-bottom: none;
}

.card-footer {
  // Less border more space
  // you can still have a border  by using `.border-top`
  border-top: none;
}

/*
 * No adapted colors inside `.card` 
 */

[class*='bg-lvl'] .card,
[class*='bg-alt-lvl'] .card {
  @each $name, $color in map-get($sgbs-adaptive-palette, 'bg-lvl1') {
    // Buttons
    .btn.btn-#{$name} {
      color: $primary-alt;
      background-color: $color;
      border: none;

      @include button-in-card-states($color);
    }

    // Outline Buttons
    .btn.btn-outline-#{$name} {
      color: $color;
      border-color: $color;

      @include button-in-card-states($color);
    }

    // Discreet Buttons
    .btn.btn-discreet-#{$name} {
      color: $color;

      @include button-in-card-states($color);
    }

    // Flat Buttons
    .btn.btn-discreet-#{$name} {
      color: $color;

      @include button-in-card-states($color);
    }

    // Badges
    .badge.badge-#{$name} {
      color: $primary-alt;
      background-color: $color;
    }

    // Outline badges
    .badge.badge-outline-#{$name} {
      color: $color;
      border-color: $color;
    }

    // Discreet badges
    .badge.badge-discreet-#{$name} {
      color: $color;
    }

    // Texts
    .text-#{$name} {
      color: $color !important;
    }
  }
}
