@mixin sgb-alert-variant($background) {
  color: $primary-alt;

  .alert-link {
    color: currentColor;
    text-decoration: none;

    // inspired by: https://css-tricks.com/almanac/properties/t/text-underline-position/
    // support: https://caniuse.com/#search=text-underline-position
    text-underline-position: under;
    text-decoration: underline;

    &:hover:not(:disabled):not(.disabled) {
      background-color: rgba($primary-alt, 0.08);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: rgba($primary-alt, 0.15);
    }
  }

  .close:hover {
    // Ensure that the hover color of the close button is color
    color: currentColor;
  }

  &.alert-dark,
  &.alert-black {
    color: $white;
  }

  &.alert-light {
    color: #000;
  }
}

@mixin sgb-alert-outline-variant($color) {
  //
  color: $color;

  border: $color 1px solid;
  background: transparent;

  .close {
    color: $close-color;
  }
}
