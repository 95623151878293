@import 'bootstrap/scss/popover';

.popover {
  min-width: $sgbs-popover-min-width;
  color: $sgbs-popover-color;
  font-family: unset;
}

.popover-header {
  border-bottom: none;
  @include variable-weight($font-weight-bold);
}
