@mixin card-border($border-width, $border-color) {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    border: $border-width solid $border-color;
    z-index: 1;
    pointer-events: none;
  }
}

@mixin card-states($color: $primary) {
  color: $color;
  border-width: 0;
  will-change: background-color;
  transition: background-color 0.3s;

  &.card-bordered {
    @include card-border($sgbs-card-border-width, $color);
  }

  &.card-raised {
    box-shadow: $box-shadow-sm;
  }

  // Filling
  &.card-filling {
    @include card-state-filling($color);
  }

  // Bordering
  &.card-bordering {
    @include card-state-bordering($color);
  }
}

@mixin card-state-filling($color: $primary) {
  &:hover {
    color: $color;
    background-color: rgba($color, $sgbs-card-hover-bg-opacity);
  }
  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active {
    color: $color;
    background-color: rgba($color, $sgbs-card-active-bg-opacity);
  }
}

@mixin card-state-bordering($color: $primary) {
  &:hover {
    color: $color;
    @include card-border($sgbs-card-border-width, $color);
  }
  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active {
    color: $color;
    @include card-border($sgbs-card-bordering-width, $color);
  }
}

@mixin card-state-raising() {
  will-change: box-shadow;
  transition: box-shadow 0.3s;

  @include hover {
    box-shadow: $box-shadow !important;
  }

  &:not(.disabled):not(:disabled):active,
  &:not(.disabled):not(:disabled).active,
  &:not(.disabled):not(:disabled):focus,
  &:not(.disabled):not(:disabled).focus {
    box-shadow: $sgbs-box-shadow-max;
  }
}
