@import 'bootstrap/scss/modal';

// Responsive modal paddings
// default is 1rem, set in Bootstrap variables

$modal-paddings: (
  sm: 1.5rem,
  lg: 2rem
);

@each $breakpoint, $value in $modal-paddings {
  @include media-breakpoint-up($breakpoint) {
    .modal-body,
    .modal-footer,
    .modal-header {
      padding: $value;
    }
  }
}

.modal-body {
  overflow-y: auto;
  padding-top: 0;
}

.modal-content {
  max-height: $sgbs-modal-content-max-height;
  border: none;
}

.modal-title {
  line-height: 1;
}
