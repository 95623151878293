@mixin variable-weight($weight) {
  font-weight: $weight;

  // @supports (font-variation-settings: normal) {
  //   & {
  //     font-weight: 400 !important;
  //   }
  // }

  // font-variation-settings: 'wght' $weight;
}

@mixin text-properties($size, $line-height, $letter-spacing) {
  font-size: $size;
  line-height: $line-height;
  letter-spacing: $letter-spacing;
}
