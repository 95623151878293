//
// Base styles
//

@import 'bootstrap/scss/button-group';

//
// Base styles
//

.btn-group,
.btn-group-vertical {
  > .btn {
    &:focus,
    &:active,
    &.active {
      z-index: 2;
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }

  .btn-toggle-primary:not([disabled]):not(.disabled) {
    border-color: $sgbs-btn-default-border-color;
    color: $primary;

    &.active:active,
    &.active {
      color: $primary;
      background-color: rgba($primary, 0.1);
      border-color: $primary;

      &:hover {
        background-color: rgba($primary, 0.05);
      }
    }

    &:active {
      color: $primary;
      background-color: rgba($primary, 0.15);
    }

    &:hover {
      background: transparent;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $primary;
    }
  }

  .btn-toggle-info:not([disabled]):not(.disabled) {
    border-color: $sgbs-btn-default-border-color;
    color: $primary;

    &.active {
      color: $info;
      background-color: rgba($info, 0.1);

      &:hover {
        background-color: rgba($info, 0.05);
      }
    }

    &:active {
      color: $info;
      background-color: rgba($info, 0.15);
    }

    &:hover {
      background: transparent;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($info, 0.25);
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      border-color: $info;
    }
  }
}
