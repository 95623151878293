@import 'bootstrap/scss/tooltip';

.tooltip {
  font-family: $font-family;
}

@each $color, $value in $theme-colors {
  .tooltip-#{$color} {
    .tooltip-inner {
      background-color: $value;
    }
  }

  .tooltip-#{$color} .bs-tooltip-top,
  .tooltip-#{$color}.bs-tooltip-top {
    .arrow {
      &::before {
        border-top-color: $value;
      }
    }
  }

  .tooltip-#{$color} .bs-tooltip-right,
  .tooltip-#{$color}.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: $value;
      }
    }
  }

  .tooltip-#{$color} .bs-tooltip-left,
  .tooltip-#{$color}.bs-tooltip-left {
    .arrow {
      &::before {
        border-left-color: $value;
      }
    }
  }

  .tooltip-#{$color} .bs-tooltip-bottom,
  .tooltip-#{$color}.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $value;
      }
    }
  }
}
