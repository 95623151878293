//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $size, $length in $sgbs-layout-spacings {
      .spacing-m#{$infix}-#{$size} {
        margin: $length !important;
      }
      .spacing-mt#{$infix}-#{$size},
      .spacing-my#{$infix}-#{$size} {
        margin-top: $length !important;
      }
      .spacing-mr#{$infix}-#{$size},
      .spacing-mx#{$infix}-#{$size} {
        margin-right: $length !important;
      }
      .spacing-mb#{$infix}-#{$size},
      .spacing-my#{$infix}-#{$size} {
        margin-bottom: $length !important;
      }
      .spacing-ml#{$infix}-#{$size},
      .spacing-mx#{$infix}-#{$size} {
        margin-left: $length !important;
      }
    }
  }
}
