//
// Base styles
//

@import 'bootstrap/scss/root';

:root {
  @each $color, $value in $sgbs-secondary-colors {
    --#{$color}: #{$value};
  }
}

:root {
  // Custom variable values only support SassScript inside `#{}`.
  @each $color, $value in $sgbs-bg-colors {
    --bg-#{$color}: #{$value};
  }

  @each $color, $value in $sgbs-bg-alt-colors {
    --bg-alt-#{$color}: #{$value};
  }
}
