// Ovveride of Bootstrap '_list-group' mixin

@mixin list-group-item-variant($state, $background, $color) {
  .list-group-item-#{$state} {
    color: $color;
    margin-bottom: 0;

    &.list-group-item-action {
      &:not(.disabled) {
        @include hover-focus {
          color: $color;
          background-color: rgba($background, $sgbs-list-group-item-opacity);
        }
      }

      &.active {
        color: $color;
        background-color: rgba($background, $sgbs-list-group-item-opacity);
        border-left-color: $color;
      }

      &.disabled,
      &:disabled {
        color: rgba($color, $sgbs-disbaled-element-opacity);
      }
    }
  }
}
