@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 100;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Thin.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Thin.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 100;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-ThinItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-ThinItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 200;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-ExtraLight.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-ExtraLight.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 200;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-ExtraLightItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-ExtraLightItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Light.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Light.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 300;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-LightItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-LightItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Regular.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Regular.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 400;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Italic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Italic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Medium.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Medium.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 500;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-MediumItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-MediumItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-SemiBold.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-SemiBold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 600;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-SemiBoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-SemiBoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Bold.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Bold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 700;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-BoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-BoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-ExtraBold.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-ExtraBold.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 800;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-ExtraBoldItalic.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-ExtraBoldItalic.woff?v=3.15') format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-Black.woff2?v=3.15') format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-Black.woff?v=3.15') format('woff');
}
@font-face {
  font-family: 'Inter';
  font-style: italic;
  font-weight: 900;
  font-display: $sgbs-font-display;
  src: url('#{$sgbs-font-path}/Inter/Inter-BlackItalic.woff2?v=3.15')format('woff2'),
    url('#{$sgbs-font-path}/Inter/Inter-BlackItalic.woff?v=3.15') format('woff');
}

// NOTO

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $sgbs-font-display;
  font-style: normal;
  font-weight: 400;
  src: url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.eot?#iefix') format('embedded-opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff2') format('woff2'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.woff') format('woff'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.otf') format('opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-regular.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $sgbs-font-display;
  font-style: normal;
  font-weight: 300;
  src: url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.eot?#iefix') format('embedded-opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.woff2') format('woff2'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.woff') format('woff'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.otf') format('opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-300.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $sgbs-font-display;
  font-style: normal;
  font-weight: 500;
  src: url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.eot?#iefix') format('embedded-opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.woff2') format('woff2'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.woff') format('woff'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.otf') format('opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-500.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $sgbs-font-display;
  font-style: normal;
  font-weight: 700;
  src: url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.eot?#iefix') format('embedded-opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.woff2') format('woff2'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.woff') format('woff'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.otf') format('opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-700.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Noto_Sans_JP';
  font-display: $sgbs-font-display;
  font-style: normal;
  font-weight: 900;
  src: url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.eot?#iefix') format('embedded-opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.woff2') format('woff2'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.woff') format('woff'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.otf') format('opentype'),
    url('#{$sgbs-font-path}/NotoSansJP/noto-sans-jp-v28-japanese-900.svg#NotoSansJP') format('svg'); /* Legacy iOS */
}
