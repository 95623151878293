.sgcib-only,
.sgcib-only-mini {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 1rem 1rem 0;
  border-color: transparent $red-socgen transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.sgcib-only-mini {
  border-width: 0 0.5rem 0.5rem 0;
}
