@mixin sgbs-footer($bgColor, $textColor) {
  color: $textColor;
  background-color: $bgColor;

  .list-unstyled .h5 {
    font-size: 1.125rem;
  }

  .sgbs-footer-item {
    border: none;
    padding: 0;
    color: $textColor;
    transition: color 0.3s;

    &:not(span):hover {
      color: $textColor;
    }
  }
}

.sgbs-footer-light {
  @include sgbs-footer(transparent, $sgbs-footer-light-text-color);
}

.sgbs-footer-dark {
  @include sgbs-footer($sg-dark-bg-1, $sgbs-footer-dark-text-color);
}

.sgbs-footer-black {
  @include sgbs-footer($sg-dark-bg-4, $sgbs-footer-black-text-color);
}
