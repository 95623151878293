//
// Base styles
//

@import 'bootstrap/scss/type';

//
// Global ?
//

* {
  font-variant-ligatures: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @include variable-weight($font-weight-bold);
}

h1,
.h1 {
  line-height: $h1-line-height;
  letter-spacing: $h1-letter-spacing;
}

h2,
.h2 {
  line-height: $h2-line-height;
  letter-spacing: $h2-letter-spacing;
}

h3,
.h3 {
  line-height: $h3-line-height;
  letter-spacing: $h3-letter-spacing;
}

h4,
.h4 {
  line-height: $h4-line-height;
  letter-spacing: $h4-letter-spacing;
}

h5,
.h5 {
  line-height: $h5-line-height;
  letter-spacing: $h5-letter-spacing;
}

h6,
.h6 {
  line-height: $h6-line-height;
  letter-spacing: $h6-letter-spacing;
}

// adaptive exceptions on light backgrounds for headings
@each $background in $sgbs-adaptive-backgrounds-light {
  .#{$background} {
    .display-5.text-success,
    .h4.text-success,
    h4.text-success,
    .h5.text-success,
    h5.text-succes {
      color: text-color-light-background($background, 'success') !important;
    }

    .display-5.text-warning,
    .h4.text-warning,
    h4.text-warning,
    .h5.text-warning,
    h5.text-warning {
      color: text-color-light-background($background, 'warning') !important;
    }

    .display-5.text-danger,
    .h4.text-danger,
    h4.text-danger,
    .h5.text-danger,
    h5.text-danger {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'danger'
      ) !important;
    }
  }
}

// adaptive exceptions on dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  .#{$background} {
    .display-5.text-success,
    .h4.text-success,
    h4.text-success,
    .h5.text-success,
    h5.text-succes {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'success'
      ) !important;
    }

    .display-5.text-warning,
    .h4.text-warning,
    h4.text-warning,
    .h5.text-warning,
    h5.text-warning {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'warning'
      ) !important;
    }

    .display-5.text-danger,
    .h4.text-danger,
    h4.text-danger,
    .h5.text-danger,
    h5.text-danger {
      color: danger-text-color-dark-background($background) !important;
    }
  }
}

//
// Headings
//

.lead {
  line-height: $sgbs-lead-line-height;
}

//
// SG-Display classes
//

.display-number {
  @include variable-weight($sgbs-display-number-weight);
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5 {
  @include variable-weight($font-weight-extrabold);
}

.display-1 {
  line-height: $display1-line-height;
  letter-spacing: $display1-letter-spacing;
}

.display-2 {
  line-height: $display2-line-height;
  letter-spacing: $display2-letter-spacing;
}

.display-3 {
  line-height: $display3-line-height;
  letter-spacing: $display3-letter-spacing;
}

.display-4 {
  line-height: $display4-line-height;
  letter-spacing: $display4-letter-spacing;
}

.display-5 {
  font-size: $sgbs-display5-size;
  letter-spacing: $display5-letter-spacing;
  line-height: $sgbs-display5-line-height;
}

//
// Blockquotes
//

.blockquote {
  @include variable-weight($sgbs-blockquote-font-weight);
  line-height: $sgbs-blockquote-line-height;
}

.blockquote-footer {
  font-size: 1rem;
  line-height: $sgbs-blockquote-line-height * $blockquote-font-size;
}

.line-height-1 {
  line-height: 1 !important;
}
