//
// Base styles
//

@import 'bootstrap/scss/utilities/shadows';

.shadow-xl {
  box-shadow: $sgbs-box-shadow-xl !important;
}

.shadow-xxl {
  box-shadow: $sgbs-box-shadow-xxl !important;
}

.shadow-max {
  box-shadow: $sgbs-box-shadow-max !important;
}
