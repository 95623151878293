.sgbs-combobox {
  position: relative;
  overflow: visible;

  > .dropdown-toggle::after {
    display: none;
  }

  .sgbs-combobox-menu {
    border: none;
    position: relative;
    margin-top: -34px;
    width: 100%;
    font-size: $font-size-base;

    .input-group-merged {
      z-index: 5;
    }

    [size='lg'] & {
      margin-top: -42px;
      font-size: $font-size-lg;
    }

    [size='md'] & {
      margin-top: -34px;
      font-size: $font-size-base;
    }

    [size='xl'] & {
      margin-top: -50px;
      font-size: $sgbs-font-size-xl;
    }

    [size='sm'] & {
      margin-top: -21px;
      font-size: $font-size-sm;
    }

    > .input-group {
      position: relative;
      z-index: 2;
      .form-control {
        box-shadow: none;
        border: 2px solid $sgbs-combobox-input-border-color;
        border-bottom: 1px solid $border-color;
      }
    }
  }

  .sgbs-combobox-menu-inner {
    position: relative;
    z-index: 1;
    margin: -2px 2px 0;
    background: $sgbs-combobox-menu-background;

    &:empty {
      &::before {
        content: 'YOUR CONTENT HERE';
        text-align: center;
        height: 120px;
        width: 100%;
        display: block;
        line-height: 120px;
        @include variable-weight($font-weight-bold);
      }
    }
  }
}
