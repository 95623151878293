@import 'bootstrap/scss/carousel';

.carousel-gallery {
  padding-bottom: 0;
  background: $bg-alt-lvl2;

  .carousel-inner {
    margin-top: $sgbs-carousel-gallery-mobile-topbar-height;
    @include media-breakpoint-up('sm') {
      width: calc(100% - #{$sgbs-carousel-gallery-sidebar-width});
      margin: 0;
    }
  }

  .carousel-indicators {
    font-size: 0;
    width: 100%;
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    position: absolute;
    background: $sgbs-carousel-preview-background;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    border: none;
    margin: 0;
    padding: $sgbs-carousel-gallery-sidebar-spacing-sm;
    height: 2 * $sgbs-carousel-gallery-sidebar-spacing-sm +
      $sgbs-carousel-gallery-thumbnail-height-sm;

    @include media-breakpoint-up('sm') {
      padding: $sgbs-carousel-gallery-sidebar-spacing;
      left: auto;
      bottom: auto;
      width: $sgbs-carousel-gallery-sidebar-width;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    li {
      margin: 0 $sgbs-carousel-gallery-thumbnail-margin 0 0;
      text-align: center;
      position: relative;
      text-indent: 0;
      background: none;
      width: auto;
      height: 100%;
      display: inline-block;

      img {
        height: $sgbs-carousel-gallery-thumbnail-height-sm;
      }

      @include media-breakpoint-up('sm') {
        display: block;
        height: auto;
        margin: 0 0 $sgbs-carousel-gallery-thumbnail-margin;

        &:last-child {
          margin-bottom: 0;
        }

        img {
          height: auto;
          opacity: $sgbs-carousel-gallery-thumbnail-alpha;
        }
      }

      &.active img {
        opacity: 1;
        outline: 2px solid $sgbs-carousel-gallery-thumbnail-active-border-color;
      }
    }
  }

  .gallery-controls {
    position: absolute;
    bottom: -1 * map-get($spacers, 4) - map-get($spacers, 2);
    left: 0;
    right: 0;
    height: map-get($spacers, 4);

    h4,
    h5 {
      margin: 0;
      line-height: map-get($spacers, 4);
    }
  }
}

.carousel:not(.carousel-gallery) .carousel-indicators li {
  width: $sgbs-carousel-indicator-size;
  height: $sgbs-carousel-indicator-size;
  background: $secondary;
  border: none;

  &.active {
    background: $primary;
  }
}
