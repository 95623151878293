//
// Base styles
//

@import 'bootstrap/scss/list-group';

// Override active item

.list-group-item {
  border-width: 0;
  border-left: 3px solid transparent;

  &.active {
    color: inherit;
    border-left-color: $primary;
    border-bottom-width: 0;
    border-top-width: 0;
  }
}

.list-group-flush .list-group-item {
  border-bottom-width: 1px;
}

// Contextual variants
//
// Add modifier classes to change text and background color on individual items.
// Organizationally, this must come after the `:hover` states.

@each $color, $value in $theme-colors {
  @include list-group-item-variant(
    $color,
    theme-color-level($color),
    theme-color-level($color)
  );
}
