.sgbs-datepicker {
  background: $bg-lvl1;
  min-width: 320px;
}

.sgbs-datepicker-inner {
  width: 320px;
  margin: 0 auto;
}

.sgbs-datepicker-calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin: 0 auto;
}

.sgbs-datepicker-calendar-item {
  display: inline-block;
  text-align: center;
  height: 2.5rem;
  vertical-align: center;
  background-color: transparent;
  font-size: 1rem;
  will-change: background-color;
  transition: background-color 300ms;
  color: $primary;
  width: 41px;
  padding: 0;

  &.inactive {
    color: $secondary;
  }

  &.active {
    background-color: $info;
    color: $white;
  }

  &:hover:not([disabled]) {
    background-color: rgba($info, 0.6);
    color: $white;
  }

  &.sgbs-datepicker-calendar-weekday {
    opacity: 1;
  }
}
