//
// Base styles
//

@import 'bootstrap/scss/buttons';

.btn {
  @include variable-weight($btn-font-weight);
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: $sgbs-button-color;

    &.btn-primary-alt {
      color: $primary;

      &:focus,
      &.focus,
      &:hover {
        color: $primary;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($primary);
      }
    }

    &:hover {
      color: $sgbs-button-hover-color;
      background-color: button-hover-background($value);
      border-color: button-hover-background($value);
    }

    &:focus,
    &.focus {
      color: $sgbs-button-color;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color($sgbs-button-color);
      background-color: $value !important;
      border-color: $value !important;
    }
  }
}

// Default button adapted
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .btn.btn-#{$name} {
      background-color: $adapted_color;
      border-color: $adapted_color;

      &:hover {
        background-color: button-hover-background($adapted_color);
        border-color: button-hover-background($adapted_color);
      }

      &:focus,
      &.focus {
        color: $sgbs-button-color;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($sgbs-button-color);
        background-color: $adapted_color !important;
        border-color: $adapted_color !important;
      }
    }
  }
}

// Discreet button adapted
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .btn.btn-discreet-#{$name} {
      color: $adapted_color;

      &:hover:not(:disabled):not(.disabled) {
        background-color: $adapted_color;
        border-color: $adapted_color;
      }

      &:not(:disabled):not(.disabled) {
        &:focus,
        &.focus {
          background-color: $adapted_color;
        }
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($adapted_color);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
}

// Discreet Button over light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .btn.btn-discreet-#{$name} {
      &:focus,
      &.focus {
        color: $white !important;
      }

      $adapted_color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        $name
      );

      &:hover:not(:disabled):not(.disabled) {
        color: $white;
        background-color: button-darken-background($adapted_color);
        border-color: button-darken-background($adapted_color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($white);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
  .#{$background} {
    .btn.btn-discreet-secondary {
      color: light-discreet-color($background, 'secondary');
    }
    .btn.btn-discreet-info {
      color: light-discreet-color($background, 'info');
    }
    .btn.btn-discreet-success {
      color: light-discreet-color($background, 'success');
    }
    .btn.btn-discreet-warning {
      color: light-discreet-color($background, 'warning');
    }
    .btn.btn-discreet-danger {
      color: light-discreet-color($background, 'danger');
    }
  }
}

// Discreet Button over dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .btn.btn-discreet-#{$name} {
      $adapted_color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        $name
      );
      
      &:focus,
      &.focus {
        color: #000 !important;
      }

      &:hover:not(:disabled):not(.disabled) {
        color: #000;
        background-color: button-lighten-background($adapted_color);
        border-color: button-lighten-background($adapted_color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color(#000);
        background-color: $adapted_color;
        border-color: $adapted_color;
      }
    }
  }
  .#{$background} {
    .btn.btn-discreet-secondary {
      color: dark-discreet-color($background, 'secondary');
    }

    .btn.btn-discreet-info {
      color: info-discreet-color-on-dark($background);
    }
    .btn.btn-discreet-danger {
      color: danger-text-color-dark-background($background);
    }
  }
}

// On dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .btn.btn-#{$name} {
      color: #000;

      &:hover {
        background-color: button-lighten-background($color);
        border-color: button-lighten-background($color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color(#000);
      }
    }
  }
}

// On light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .btn.btn-#{$name} {
      color: $white;

      &:hover {
        background-color: button-darken-background($color);
        border-color: button-darken-background($color);
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($white);
      }
    }
  }
}

.btn-link {
  @include hover {
    text-decoration: $sgbs-btn-link-decoration;
  }

  &:focus,
  &.focus {
    text-decoration: $sgbs-btn-link-decoration;
    box-shadow: $btn-focus-box-shadow;
  }
}

//
// Button Sizes
// Need to override Bootstrap mixin calling because of font-size
// ie. LG button font-size is not the global LG font-size, used for LG text for example
//
.btn-lg {
  @include button-size(
    $btn-padding-y-lg,
    $btn-padding-x-lg,
    $sgbs-btn-font-size-lg,
    $btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $btn-padding-y-sm,
    $btn-padding-x-sm,
    $sgbs-btn-font-size-sm,
    $btn-line-height-sm,
    $btn-border-radius-sm
  );
}

// XL Size doesn't exist in Bootstrap
.btn-group-xl .btn,
.btn-xl {
  @include button-size(
    $sgbs-btn-padding-y-xl,
    $sgbs-btn-padding-x-xl,
    $sgbs-btn-font-size-xl,
    $sgbs-btn-line-height-xl,
    $sgbs-btn-border-radius-xl
  );
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

//
// Alternate buttons
//

// Add custom theme color buttons
// Inspired by https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L59-L73

@each $name, $values in $sgbs-theme-colors-with-lighter-pair {
  $color: nth($values, 1);
  $lighter-color: nth($values, 2);

  //

  .btn {
    &.btn-flat-#{$name} {
      @include sgbs-button-flat-variant($color);
    }

    &.btn-flat {
      @include sgbs-button-flat-variant($primary);
      &:not(:hover):not(:active):not(.active):not(:focus):not(.focus):not([disabled]):not(.disabled) {
        color: $secondary;
      }
    }

    &.btn-discreet-#{$name} {
      @include sgbs-button-discreet-variant($color);
    }

    &.btn-outline-#{$name} {
      @include sgbs-button-outline-variant($color);
    }

    &.btn-toggle-#{$name} {
      @include sgbs-button-toggle-variant($color, $lighter-color);
    }
  }
}

@each $name, $color in $sgbs-secondary-colors {
  .btn-#{$name} {
    @include sgbs-button-variant(
      $color,
      lighten($color, $sgbs-lighten-color-ratio)
    );
  }

  .btn-flat-#{$name} {
    @include sgbs-button-flat-variant($color);
  }

  .btn-discreet-#{$name} {
    @include sgbs-button-discreet-variant($color);
  }

  .btn-outline-#{$name} {
    @include sgbs-button-outline-variant($color);
  }

  .btn-toggle-#{$name} {
    @include sgbs-button-toggle-variant(
      $color,
      lighten($color, $sgbs-lighten-color-ratio)
    );
  }
}

.btn-default,
.sgbs-btn-default {
  border: $border-width solid $sgbs-btn-default-border-color;
  background-color: transparent;
  color: $primary;

  &:not(:disabled):not(.disabled) {
    &:hover {
      background-color: $sgbs-btn-default-bg-hover;
    }

    &:focus {
      background-color: $sgbs-btn-default-bg-focus;
    }

    &:active {
      background-color: $sgbs-btn-default-bg-active;
    }
  }
}

.btn {
  &:disabled,
  &.disabled {
    .icon {
      cursor: default;
    }
  }
}

*:not(.btn-group):not(.input-group-prepend):not(.input-group-append) {
  > .btn.btn-xl {
    @include btn-sizing(
      $sgbs-btn-padding-y-xl,
      $sgbs-btn-padding-x-xl,
      $sgbs-btn-icon-size-xl
    );
  }

  > .btn.btn-lg {
    @include btn-sizing(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $sgbs-btn-icon-size-lg
    );
  }

  > .btn.btn-sm {
    @include btn-sizing(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $sgbs-btn-icon-size-sm
    );
  }

  > .btn {
    @include btn-sizing($btn-padding-y, $btn-padding-x, $sgbs-btn-icon-size-md);
  }
}

//
// OUTLINE
//

// Outline button adapted
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .btn.btn-outline-#{$name} {
      border-color: $adapted_color;
      color: $adapted_color;
    }
  }
}

// Outline button over Light background
@each $background in $sgbs-adaptive-backgrounds-light {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );

    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );

    .#{$background} .btn.btn-outline-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        color: $white;
        background-color: button-darken-background($adapted_color);
        border-color: button-darken-background($adapted_color);
      }

      &:focus,
      &.focus {
        background: $adapted_color;
        color: $white;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color($white);
      }
    }
  }
}

// Outline button over Dark background
@each $background in $sgbs-adaptive-backgrounds-dark {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );

    .#{$background} .btn.btn-outline-#{$name} {
      &:hover:not(:disabled):not(.disabled) {
        color: #000;
        background-color: button-lighten-background($adapted_color);
        border-color: button-lighten-background($adapted_color);
      }

      &:focus,
      &.focus {
        background: $adapted_color;
        color: #000;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        color: button-active-color(#000);
      }
    }
  }
}

//
// EXCEPTIONS
//

.btn.btn-primary:not(:disabled):not(.disabled) {
  &:hover {
    background-color: button-primary-hover-background();
    border-color: button-primary-hover-background();
  }
}

.btn.btn-outline-primary-alt {
  &:not(:disabled):not(.disabled):hover,
  &:not(:disabled):not(.disabled).hover {
    color: $primary !important;
  }
}

.btn.btn-toggle-primary-alt.active {
  color: $primary !important;
}

.btn.btn-primary-alt {
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-color: button-primary-alt-active-background() !important;
  }
}

.btn {
  &.btn-dark,
  &.btn-black {
    color: $white !important;

    &:hover {
      color: $white !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color($white);
    }
  }

  &.btn-white,
  &.btn-light {
    color: #000 !important;

    &:hover {
      color: #000 !important;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color(#000);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      color: button-active-color(#000);
    }
  }
}

.btn.btn-discreet-primary-alt {
  &:not(:disabled):not(.disabled) {
    &:hover {
      color: $primary;
    }
  }
}

.btn.btn-outline-primary-alt {
  &:not(:disabled):not(.disabled) {
    &:focus {
      color: $primary;
    }
  }
}
