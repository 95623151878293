*::-webkit-scrollbar {
  width: $sgbs-scrollbar-size;
}

*::-webkit-scrollbar:horizontal {
  height: $sgbs-scrollbar-size;
}

.scrollbar-sm::-webkit-scrollbar {
  width: $sgbs-scrollbar-size-sm;
}

.scrollbar-sm::-webkit-scrollbar:horizontal {
  height: $sgbs-scrollbar-size-sm;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*:hover::-webkit-scrollbar-track {
  background: $sgbs-scrollbar-background;
}

*::-webkit-scrollbar-thumb {
  background: $sgbs-scrollbar-thumb-color;
}

*:hover::-webkit-scrollbar-thumb {
  background: $sgbs-scrollbar-thumb-active-color;
}

//
// NO Scrollbar customization on document body
// Had to unset custom rules because :not(body)::*** doesn't work as it should
//
body,
.no-sgbs-custom-scrollbar {
  &::-webkit-scrollbar {
    width: $sgbs-scrollbar-size-large;
  }

  &::-webkit-scrollbar-track {
    background: $sgbs-scrollbar-background;
  }

  &::-webkit-scrollbar-button {
    background-position: top;
    background-color: $sgbs-scrollbar-background;
    height: 24px;
    &:decrement {
      background-image: url($sgbs-scrollbar-hover-button-up);
    }
    &:increment {
      background-image: url($sgbs-scrollbar-hover-button-down);
    }
  }

  &::-webkit-scrollbar-thumb,
  &:hover::-webkit-scrollbar-thumb {
    background: $sgbs-scrollbar-thumb-active-color;

    // Border left/right tricks explained above...
    border-left: 3px solid $sgbs-scrollbar-background;
    border-right: 3px solid $sgbs-scrollbar-background;
  }
}
