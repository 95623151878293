//
// Base styles
//

@import 'bootstrap/scss/dropdown';

//
// Base sg-bootstrap dropdown
//

.dropdown-header {
  padding: $sgbs-dropdown-header-spacing-top $dropdown-item-padding-x
    $sgbs-dropdown-header-spacing-bottom;
  @include variable-weight($font-weight-medium);
}

.dropdown-menu {
  box-shadow: $sgbs-box-shadow-xl;
  margin: 1px 0 0 -2px;
}

.dropdown-item {
  &:active:not(.active) {
    color: $dropdown-link-color;
    background-color: rgba($dropdown-link-hover-bg, 0.2);
  }

  &.active {
    @include variable-weight($font-weight-medium);
    background-color: $dropdown-link-active-bg;
    display: flex;
    justify-content: space-between;

    &::after {
      display: inline-block;
      vertical-align: middle;
      content: '';
      width: $sgbs-dropdown-icon-size;
      height: $sgbs-dropdown-icon-size;
      margin-left: $dropdown-item-padding-x;
      background: transparent
        str-replace(
          url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 26 26' %3e%3cpath fill='#{$dropdown-link-active-color}' d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3e%3c/svg%3e"),
          '#',
          '%23'
        )
        no-repeat center;
      background-size: cover;
    }
  }
}

.btn-group {
  // XL
  .btn-xl + .dropdown-menu {
    font-size: $sgbs-btn-font-size-xl;

    .dropdown-item {
      padding: $sgbs-dropdown-link-padding-y-xl $sgbs-btn-padding-x-xl;

      &.active {
        &.active::after {
          width: $sgbs-dropdown-icon-size-xl;
          height: $sgbs-dropdown-icon-size-xl;
          margin-left: $sgbs-btn-padding-x-xl;
        }
      }
    }

    .dropdown-header {
      padding: $sgbs-dropdown-header-spacing-top-xl $sgbs-btn-padding-x-xl
        $sgbs-dropdown-header-spacing-bottom-xl;
      font-size: $sgbs-dropdown-header-font-size-xl;
    }
  }

  // LG
  .btn-lg + .dropdown-menu {
    font-size: $sgbs-btn-font-size-lg;

    .dropdown-item {
      padding: $sgbs-dropdown-link-padding-y-lg $btn-padding-x-lg;

      &.active::after {
        width: $sgbs-dropdown-icon-size-lg;
        height: $sgbs-dropdown-icon-size-lg;
        margin-left: $btn-padding-x-lg;
      }
    }

    .dropdown-header {
      padding: $sgbs-dropdown-header-spacing-top-lg $btn-padding-x-lg
        $sgbs-dropdown-header-spacing-bottom-lg;
      font-size: $sgbs-dropdown-header-font-size-lg;
    }
  }

  // SM
  .btn-sm + .dropdown-menu {
    font-size: $sgbs-btn-font-size-sm;

    .dropdown-item {
      padding: $sgbs-dropdown-link-padding-y-sm $btn-padding-x-sm;

      &.active {
        &.active::after {
          width: $sgbs-dropdown-icon-size-sm;
          height: $sgbs-dropdown-icon-size-sm;
          margin-left: $btn-padding-x-sm;
        }
      }
    }

    .dropdown-header {
      padding: $sgbs-dropdown-header-spacing-top-sm $btn-padding-x-sm
        $sgbs-dropdown-header-spacing-bottom-sm;
      font-size: $sgbs-dropdown-header-font-size-sm;
    }
  }
}

// Toggle split
//
.dropdown-toggle-split {
  padding-right: $btn-padding-x;
  padding-left: $btn-padding-x;

  &.btn-sm {
    padding-right: $btn-padding-x-sm;
    padding-left: $btn-padding-x-sm;
  }

  &.btn-lg {
    padding-right: $btn-padding-x-lg;
    padding-left: $btn-padding-x-lg;
  }

  &.btn-xl {
    padding-right: $sgbs-btn-padding-x-xl * 0.7;
    padding-left: $sgbs-btn-padding-x-xl * 0.7;
  }
}

.btn-sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm;
  padding-left: $btn-padding-x-sm;
}

.btn-lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg;
  padding-left: $btn-padding-x-lg;
}

.btn-xl + .dropdown-toggle-split {
  padding-right: $sgbs-btn-padding-x-xl * 0.7;
  padding-left: $sgbs-btn-padding-x-xl * 0.7;
}

// Caret
//

@mixin btn-caret-sizing($padding-y, $padding-x, $icon-size) {
  &::after {
    margin-top: -1 * $btn-border-width;
    font-size: $icon-size;
    line-height: 1;
  }
  &:not(.dropdown-toggle-split)::after {
    margin-left: ($padding-x - $padding-y) / 2;
  }
}

.dropdown-toggle::after {
  vertical-align: middle;
}

@each $color, $value in $theme-colors {
  .dropdown-caret-#{$color}::after {
    color: $value;
  }
}

.dropdown-toggle {
  @include btn-caret-sizing(
    $btn-padding-y,
    $btn-padding-x,
    $sgbs-btn-icon-size-md
  );

  &.btn-xl {
    @include btn-caret-sizing(
      $sgbs-btn-padding-y-xl,
      $sgbs-btn-padding-x-xl,
      $sgbs-btn-icon-size-xl
    );
  }

  &.btn-lg {
    @include btn-caret-sizing(
      $btn-padding-y-lg,
      $btn-padding-x-lg,
      $sgbs-btn-icon-size-lg
    );
  }

  &.btn-sm {
    @include btn-caret-sizing(
      $btn-padding-y-sm,
      $btn-padding-x-sm,
      $sgbs-btn-icon-size-sm
    );
  }
}
