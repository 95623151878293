$border-sizes: 'sm' 'md' 'lg' 'xl';
@each $borderSize in $border-sizes {
  .border-#{$borderSize} {
    border-width: map-get($sgbs-borders, $borderSize) !important;
  }
}

// Secondary palette borders
@each $color, $value in $sgbs-secondary-colors {
  .border-#{$color} {
    border-color: $value !important;
  }
}
