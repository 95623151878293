@import 'bootstrap/scss/spinners';

//
// Component ratio
// Component should respect the same sizing ratio than the white rectangle inside SG logo
//
$sgbs-spinner-rectangle-ratio: 6/50;

@mixin spinner-ratio($size) {
  width: $size;
  height: $size * $sgbs-spinner-rectangle-ratio;
}

.spinner-grow {
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border-radius: 0;
  opacity: 1;
  animation: none;

  font-size: $sgbs-spinner-font-size;
  white-space: nowrap;
  line-height: 2;

  &::after {
    content: '';
    display: block;
    width: $spinner-width;
    height: $spinner-width * $sgbs-spinner-rectangle-ratio;
    background: $sgbs-spinner-color;
    animation: blink $sgbs-spinner-speed infinite;
  }
}

@keyframes blink {
  0% {
    background: $sgbs-spinner-color;
  }
  40% {
    background: transparent;
  }
  100% {
    background: $sgbs-spinner-color;
  }
}

.spinner-grow-sm::after {
  @include spinner-ratio($spinner-width-sm);
}

.spinner-grow-xs::after {
  @include spinner-ratio($sgbs-spinner-width-xs);
}
