.sgbs-timeline {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:not(.sgbs-timeline-alternative) .sgbs-timeline-item {
    &::after {
      content: '';
      position: absolute;
      display: block;
      height: $sgbs-timeline-dot-size;
      width: $sgbs-timeline-dot-size;
      background: currentColor;
      border-radius: 50%;
      bottom: -0.5 * $sgbs-timeline-dot-size;
      left: -0.5 * $sgbs-timeline-dot-size;
    }

    &:last-child {
      border-bottom: none;
    }

    .sgbs-timeline-day,
    .sgbs-timeline-date {
      margin-left: -0.5 * $sgbs-timeline-dot-size;
    }
  }
}

.sgbs-timeline-item {
  flex: 1;
  padding-bottom: 2 * $sgbs-timeline-padding;
  border-bottom: $sgbs-timeline-border-width solid $sgbs-timeline-active-color;
  color: $sgbs-timeline-active-color;
  position: relative;

  &.inactive {
    border-bottom-color: $sgbs-timeline-inactive-color;
    color: $sgbs-timeline-inactive-color;

    &::before {
      background-color: $secondary !important;
    }

    .sgbs-timeline-day,
    .sgbs-timeline-date {
      color: $secondary;
    }
  }
}

.sgbs-timeline:not(.sgbs-timeline-vertical) .sgbs-timeline-item.active {
  background: $sgbs-timeline-active-background;
}

.sgbs-timeline-day {
  display: block;
  font-size: $sgbs-timeline-day-font-size;
  text-transform: uppercase;
}

.sgbs-timeline-date {
  display: block;
  @include variable-weight($font-weight-normal);

  .active & {
    @include variable-weight($sgbs-timeline-active-font-weight);
  }
}

.sgbs-timeline-alternative {
  .sgbs-timeline-item {
    padding: 0.5 * $sgbs-timeline-padding 3 * $sgbs-timeline-padding;
    &::before {
      content: '';
      display: block;
      height: $sgbs-timeline-dot-size;
      width: $sgbs-timeline-dot-size;
      background: currentColor;
      position: absolute;
      top: $sgbs-timeline-padding;
      left: $sgbs-timeline-padding;
    }
  }
}

.sgbs-timeline-vertical {
  flex-direction: column;

  .sgbs-timeline-item {
    border-bottom: none;
    padding-left: 2 * $sgbs-timeline-padding;
    border-left: $sgbs-timeline-border-width solid $sgbs-timeline-active-color;
  }

  .sgbs-timeline-item::after {
    bottom: auto;
    top: -0.5 * $sgbs-timeline-dot-size;
  }

  .sgbs-timeline-day {
    margin-top: -1 * $sgbs-timeline-dot-size;
  }

  &:not(.sgbs-timeline-alternative) .sgbs-timeline-item:last-child {
    border-left: none;
  }
}
