@mixin sgbs-pagelink-styles($background, $bordercolor, $color) {
  background: $background;
  border-color: $bordercolor;
  color: $color;
}

@mixin sgbs-pagelink-active($background, $bordercolor, $color) {
  .page-item.active .page-link,
  .page-link:focus,
  .page-link:active,
  .page-link:hover {
    @include sgbs-pagelink-styles($background, $bordercolor, $color);
  }
}

@mixin sgbs-pagination-size($height, $font-size, $line-height) {
  .page-link {
    font-size: $font-size;
    height: $height;
    min-width: $height;
    line-height: $line-height;
  }
}
