//
// Base styles
//

@import 'bootstrap/scss/nav';

//
// Link
//

.nav-link {
  // Ensure that the nav stays the same height as in bootstrap@4.0.0
  // even if the font-size is not 16px.
  line-height: 1.5rem; // 24px
  @include variable-weight($sgbs-nav-link-weight);

  &.active {
    color: $sgbs-nav-link-active-color;
    @include variable-weight($sgbs-nav-link-active-font-weight);
  }

  @include hover-focus {
    color: $sgbs-nav-link-hover-color;
  }

  // Ensure that the disabled state is right
  &:disabled,
  &.disabled {
    cursor: default;
    pointer-events: none;
    color: $nav-link-disabled-color;
  }
}

//
// Tabs
//

.nav-tabs {
  @each $name, $value in $theme-colors {
    // Ensure that the parent border-color is communicated to the child
    &.border-#{$name} {
      .nav-link.active,
      .nav-item.show .nav-link {
        border-color: $value $value $nav-tabs-link-active-bg;
      }
    }
  }
}

//
// Pills
//

.nav-pills {
  .nav-link {
    // Separate y padding so the border is only highlighting the text
    padding: $nav-link-padding-y $nav-link-padding-x
      calc(#{$nav-link-padding-y} - #{$sgbs-nav-pills-active-border-width});

    border-bottom: ($sgbs-nav-pills-active-border-width - 1px) solid transparent;
  }

  .nav-link.active,
  .show > .nav-link {
    border-bottom: $sgbs-nav-pills-active-border-width solid $primary;
    // Put the bottom border on the parent .nav border
    margin-bottom: -1px;
  }
}

//
// Compact pills
//

.nav-compact-pills {
  .nav-link {
    // Separate y padding so the border is only highlighting the text
    padding: $nav-link-padding-y $nav-link-padding-x
      calc(#{$nav-link-padding-y} - #{$sgbs-nav-pills-active-border-width});

    &.active {
      // The bottom border should only highlight the text
      padding-left: 0;
      padding-right: 0;
      // We are going to use :before/:after to fill the remaining space
      margin: 0 $nav-link-padding-x;
    }

    border-bottom: ($sgbs-nav-pills-active-border-width - 1px) solid transparent;
  }

  .nav-link.active,
  .show > .nav-link {
    border-bottom: $sgbs-nav-pills-active-border-width solid $primary;
    // Put the bottom border on the parent .nav border
    margin-bottom: -1px;
  }
}

//
// Vertical pills
//

.nav-vertical-pills {
  flex-direction: column;

  .nav-link {
    // Ensure to use a standard y spacing
    padding-left: map-get($spacers, 4);
  }

  .nav-link.active,
  .show > .nav-link {
    border-left: $sgbs-nav-pills-active-border-width solid $primary;
    // Ensure that the border doesn't interfere with the padding space
    padding-left: calc(
      #{map-get($spacers, 4)} - #{$sgbs-nav-pills-active-border-width}
    );
  }

  // Nested vertical navs
  .nav-vertical-pills {
    border-left: 1px solid $border-color;
    margin: map-get($spacers, 2) 0 map-get($spacers, 2) map-get($spacers, 4);

    .nav-link.active {
      background-color: transparent !important;
      border-left: none;
      color: map-get($theme-colors, 'info');
      @include variable-weight($font-weight-semibold);
      padding-left: calc(#{map-get($spacers, 4)} - 3px);
    }
  }
}

//
// Nav hover
//

.nav-hover {
  .nav-link {
    @include hover-focus {
      background-color: rgba(
        $sgbs-nav-link-color,
        $sgbs-nav-link-hover-focus-opacity
      );
    }

    &:not([disabled]):not(.disabled).active,
    &:not([disabled]):not(.disabled):active {
      background-color: rgba(
        $sgbs-nav-link-color,
        $sgbs-nav-link-active-opacity
      );
    }
    // Nav pills have some missing space at right and left due to the bottom
    // border on the `.nav-link` being only under the text.
    // see above `.nav-compact-pills .nav-link.active` rule.
    &:not([disabled]):not(.disabled).active {
      @at-root {
        // Pills edge case
        //
        .nav-compact-pills#{&} {
          position: relative;

          &::before,
          &::after {
            content: '';
            width: 1rem;
            position: absolute;
            top: 0;
            bottom: -$sgbs-nav-pills-active-border-width;
            background-color: rgba(
              $sgbs-nav-link-color,
              $sgbs-nav-pills-opacity
            );
          }

          &::before {
            left: -1rem;
          }

          &::after {
            right: -1rem;
          }
        }
      }
    }
  }
}
