.icon {
  font-weight: normal;
  font-style: normal;
  display: inline-block;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  vertical-align: middle;

  cursor: $sgbs-icon-cursor;
  user-select: none;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';

  // HACK(@fzibi030518): invisible rotation visually fix pixellised iconfont when size is too small
  transform: rotate(0.03deg);

  > svg {
    fill: currentColor;
    height: 1em;
    width: 1em;
    margin-top: -0.5em;
  }
}

button .icon,
a .icon {
  cursor: pointer;
  line-height: 1;
}

//
// Sizing
//

.icon-sm {
  font-size: $sgbs-icons-size-sm;
}

.icon-md {
  font-size: $sgbs-icons-size-md;
}

.icon-lg {
  font-size: $sgbs-icons-size-lg;
}

.icon-xl {
  font-size: $sgbs-icons-size-xl;
}
