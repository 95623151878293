//
// Base styles
//

@import 'bootstrap/scss/utilities/text';

.text-default {
  @include variable-weight($font-weight-normal);
  font-size: $font-size-base !important;
  line-height: $line-height-base !important;
}

//
// Sizing
//

.text-small {
  @include text-properties($font-size-sm, $line-height-sm, $letter-spacing-sm);
}

.text-large {
  @include text-properties($font-size-lg, $line-height-lg, $letter-spacing-lg);
}

.text-xlarge {
  @include text-properties(
    $sgbs-font-size-xl,
    $sgbs-line-height-xl,
    $letter-spacing-xl
  );
}

.text-xxlarge {
  @include text-properties(
    $sgbs-font-size-xxl,
    $sgbs-line-height-xxl,
    $letter-spacing-xxl
  );
}

// Weight and italics

.font-weight-light {
  @include variable-weight($font-weight-light);
}

.font-weight-normal {
  @include variable-weight($font-weight-normal);
}

.font-weight-semibold {
  @include variable-weight($font-weight-semibold);
}

.font-weight-bold {
  @include variable-weight($font-weight-bold);
}

.font-weight-extrabold {
  @include variable-weight($font-weight-extrabold);
}

.font-weight-medium {
  @include variable-weight($font-weight-medium);
}

// Figures
.text-figures {
  line-height: 1;
  @include variable-weight($font-weight-medium);
}

.tabular-figures {
  font-feature-settings: $sgbs-font-feature-settings, 'tnum';
  letter-spacing: $sgbs-tabular-figures-letter-spacing;
  line-height: 1;
  @include variable-weight($font-weight-medium);
}

// Secondary color palette utility
@each $color, $value in $sgbs-secondary-colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}

// adaptive colors on `.text-*`
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .text-#{$name} {
      color: $adapted_color !important;
    }
  }
}

// adaptive exceptions on light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  .#{$background} {
    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: text-color-light-background($background, 'success') !important;
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: text-color-light-background($background, 'warning') !important;
    }

    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'danger'
      ) !important;
    }
  }
}

// adaptive excceptions on dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  .#{$background} {
    .text-danger:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: danger-text-color-dark-background($background) !important;
    }

    .text-success:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'success'
      ) !important;
    }
    .text-warning:not(h1):not(.h1):not(h2):not(.h2):not(h3):not(.h3):not(.display-1):not(.display-2):not(.display-3):not(.display-4) {
      color: map-get(
        map-get($sgbs-adaptive-palette, $background),
        'warning'
      ) !important;
    }
  }
}
