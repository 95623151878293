//
// Overflow utility classes
//

@import 'bootstrap/scss/utilities/overflow';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .overflow#{$infix}-visible {
      overflow: visible !important;
    }
  }
}
