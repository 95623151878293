//
// Base styles
//

@import 'bootstrap/scss/alert';

//
// Dismissible alerts
//
.alert-dismissible {
  // Makes alerts allow svg close icon
  .close {
    padding: 0 calc(#{$alert-padding-x} - 5px);
    top: 0;
    bottom: 0;
    max-height: map-get($spacers, 5);

    > svg {
      $size: 1.5rem;
      fill: currentColor;
      width: $size;
      height: $size;
    }
  }
}

//
// Alternate styles
//
@each $color, $value in $theme-colors {
  .alert-#{$color} {
    @include sgb-alert-variant(theme-color-level($color, $alert-bg-level));
  }

  .alert-outline-#{$color} {
    @include sgb-alert-outline-variant($value);
  }
}

// adapted Alerts
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .alert.alert-#{$name} {
      background-color: $adapted_color;
    }
  }
}
