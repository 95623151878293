.sgbs-slider {
  input[type='range'] {
    background: $sgbs-slider-background-color;

    &::-webkit-slider-thumb {
      background: str-replace(
        url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">\
        <polygon points="8,0 0,8, 16,8" fill="#{$sgbs-slider-anchor-color}" />\
        </svg>'),
        '#',
        '%23'
      );

      &:hover,
      &:active {
        background: str-replace(
          url('data:image/svg+xml,<svg width="16" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 24">\
          <polygon points="8,0 0,8, 16,8" fill="#{$sgbs-slider-anchor-color}" />\
          <rect width="16" height="16" fill="#{$sgbs-slider-anchor-color}" y="8" />\
          </svg>'),
          '#',
          '%23'
        );
      }
    }
  }
}

.sgbs-frame {
  input[type='range'] {
    border-bottom: $sgbs-slider-frame-border-width solid
      $sgbs-slider-frame-border-color;
  }
  input[type='range']::-webkit-slider-thumb,
  input[type='range']::-webkit-slider-thumb:hover,
  input[type='range']::-webkit-slider-thumb:active {
    background: str-replace(
      url('data:image/svg+xml,<svg width="8" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 24">\
      <polygon points="8,0 0,8, 8,8" fill="#{$sgbs-slider-anchor-color}" />\
      <rect width="8" height="16" fill="#{$sgbs-slider-anchor-color}" y="8" />\
      </svg>'),
      '#',
      '%23'
    );
    background-repeat: no-repeat;
  }

  input[type='range'] + input[type='range']::-webkit-slider-thumb,
  input[type='range'] + input[type='range']::-webkit-slider-thumb:hover,
  input[type='range'] + input[type='range']::-webkit-slider-thumb:active {
    background: str-replace(
      url('data:image/svg+xml,<svg width="8" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 24">\
      <polygon points="0,0 0,8, 8,8" fill="#{$sgbs-slider-anchor-color}" />\
      <rect width="8" height="16" fill="#{$sgbs-slider-anchor-color}" y="8" />\
      </svg>'),
      '#',
      '%23'
    );
    background-position: right;
    background-repeat: no-repeat;
  }
}
