@import 'bootstrap/scss/progress';

.progress {
  overflow: visible; // TO ALLOW PROGRESS VALUE TO BE OUTSIDE THE BAR
}

.progress-sm {
  height: $sgbs-progress-height-small;
}

.progress-lg {
  height: $sgbs-progress-height-large;
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    $primary-alt 25%,
    $progress-bg 25%,
    $progress-bg 50%,
    $primary-alt 50%,
    $primary-alt 75%,
    $progress-bg 75%,
    $progress-bg
  );
}

.progress-bar {
  text-align: right;
  overflow: visible;
}

.progress-bar-label {
  padding-right: map-get($spacers, 2);
}

.progress-bar-label-over {
  color: $primary;
  margin-top: -2rem;
}
