@mixin input-group-structure($padding-x, $height) {
  .input-group-prepend,
  .input-group-append {
    height: $height;
  }

  .input-group-merged {
    &.input-group-prepend {
      left: $padding-x;

      ~ .form-control {
        padding-left: 3 * $padding-x;
      }
    }
    &.input-group-append {
      right: 0;

      > .icon {
        padding: 0 $padding-x;
      }

      ~ .form-control {
        padding-right: 4 * $padding-x;
      }
    }
  }
}
