//

.upload-squares {
  width: 1rem;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::before,
  &::after {
    content: '';
    width: 1rem;
    height: 1rem;
  }

  &:first-child {
    &::before {
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
    &::after {
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
    }
  }

  &:last-child {
    right: 0;
    left: auto;

    &::before {
      border-top: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
    &::after {
      border-bottom: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }
  }
}

.upload-progress {
  height: 3px;
}
