//
// Base styles
//

@import 'bootstrap/scss/utilities/background';

//
// Additional background colors
//

@each $color, $value in $sgbs-bg-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $value in $sgbs-bg-alt-colors {
  @include bg-variant('.bg-alt-#{$color}', $value);
}

// Background color variants set over every themes
//
@each $color, $value in $sgbs-all-bg-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

@each $color, $value in $sgbs-secondary-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

// Adapted backgrounds
//
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .bg-#{$name} {
      background-color: $color !important;
    }
  }
}
