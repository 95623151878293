//
// Base styles
//
@import 'bootstrap/scss/custom-forms';

// Checkboxes
//

.custom-checkbox {
  .custom-control-label {
    cursor: $sgbs-custom-form-cursor;

    &::before {
      // We are using an icon too for the unchecked state
      background-image: $sgbs-custom-checkbox-indicator-icon-unchecked;
      background-color: $custom-control-indicator-checked-bg;
    }

    &::after {
      cursor: $sgbs-custom-form-cursor;
    }
  }
}

// Radios
//

.custom-radio {
  .custom-control-label::after {
    cursor: $sgbs-custom-form-cursor;
  }

  .custom-control-label::before {
    // We are using an icon too for the unchecked state
    background-image: $sgbs-custom-radio-indicator-icon-unchecked;
  }
}

.disabled,
:disabled {
  .custom-control-label {
    &::before,
    &::after {
      cursor: default;
    }
  }
}

.custom-control {
  .custom-control-input:disabled ~ .custom-control-label {
    cursor: default;
    &::before,
    &::after {
      opacity: $custom-control-disabled-opacity;
      cursor: default;
    }
  }

  @each $name, $value in $theme-colors {
    &.custom-control-#{$name} {
      &.custom-checkbox .custom-control-input:checked ~ .custom-control-label {
        &::after {
          @include sgbs-custom-checkbox-variant($value);
        }
      }

      &.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label {
        &::after {
          @include sgbs-custom-checkbox-indeterminate-variant($value);
        }
      }

      &.custom-radio .custom-control-input:checked ~ .custom-control-label {
        &::after {
          @include sgbs-custom-radio-variant($value);
        }
      }
    }
  }
}

.custom-switch {
  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      transform: translateX(1.125rem);
    }
  }

  .custom-control-input,
  .custom-control-input:checked,
  .custom-control-input:focus,
  .custom-control-input:active {
    ~ .custom-control-label {
      white-space: nowrap;

      &::before {
        height: calc(
          #{$custom-switch-indicator-size} + 2 * #{$sgbs-switch-padding}
        );
        top: -$sgbs-switch-border-width;
        border: $sgbs-switch-border-width solid $sgbs-switch-border-color;
        cursor: pointer;
      }

      &::after {
        width: $custom-switch-indicator-size;
        height: $custom-switch-indicator-size;
        margin-left: $sgbs-switch-padding;
        top: calc(#{$custom-switch-indicator-size} - #{$sgbs-switch-padding});
        transition: transform $sgbs-switch-translation-time $sgbs-switch-easing,
          background-color $sgbs-switch-fade-time;
        cursor: pointer;
      }
    }

    &:not(:disabled) + .custom-control-label {
      &::before {
        background: transparent;
      }

      &::after {
        background: $sgbs-switch-square-color;
      }
    }
  }

  .custom-control-input:disabled ~ .custom-control-label {
    &::before {
      background: transparent;
    }
    &::after {
      background: $secondary;
    }
    &::before,
    &::after {
      opacity: $sgbs-disbaled-element-opacity;
    }
  }

  .custom-control-input:checked:not(:disabled) ~ .custom-control-label {
    &::before {
      border-color: $sgbs-switch-active-color;
    }
  }

  // Colored
  &.sgbs-switch-colored {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: rgba(
          $sgbs-switch-colored,
          $sgbs-switch-colorized-active-opacity
        );
      }

      &::after {
        background: $sgbs-switch-colored;
      }
    }
  }
}

.custom-switch {
  .custom-control-input:focus {
    &:not(:disabled) + .custom-control-label {
      &::before {
        box-shadow: $btn-focus-box-shadow;
      }
    }
  }

  .custom-control-input:checked:not(:disabled) + .custom-control-label {
    &::before {
      border-color: rgba(
        $sgbs-switch-active-color,
        $sgbs-switch-colorized-active-opacity
      );
    }

    &::after {
      background: $sgbs-switch-active-color;
    }
  }

  // Colored
  &.sgbs-switch-colored {
    .custom-control-input:checked ~ .custom-control-label {
      &::before {
        border-color: rgba(
          $sgbs-switch-colored,
          $sgbs-switch-colorized-active-opacity
        );
      }

      &::after {
        background: $sgbs-switch-colored;
      }
    }
  }
}
