@import 'bootstrap/scss/toasts';

// Toast
//

$close-icon-size: 1.5rem;
$close-button-size: calc(#{$toast-padding-y + $close-icon-size} + 5px);

.toast {
  width: 100%;
  border-top: $border-width solid transparent;
  background: $sgbs-toast-bg;
  position: relative;

  .close {
    position: absolute;
    right: 0;
    top: 0;
    width: $close-button-size;
    height: $close-button-size;
  }
}

.toast-header {
  background: map-get($sgbs-bg-colors, 'lvl1');

  &::before {
    content: '';
    width: $sgbs-toast-size;
    height: $sgbs-toast-size;
    margin-right: $sgbs-toast-size;
    display: inline-block;
  }
}

.toast-body {
  padding-top: 0;
}

.toast-footer {
  display: flex;
  justify-content: flex-end;
  padding: 0 $toast-padding-x $toast-padding-y;
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
  .toast-#{$color} {
    border-color: $value;

    .toast-header {
      color: $value;

      &::before {
        background: $value;
      }
    }
  }
}

// adapted Alerts
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .toast-#{$name} {
      border-color: $adapted_color;
      .toast-header {
        color: $adapted_color;
      }
    }
  }
}
