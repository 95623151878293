// Bootstrap froms mixin overrides

@mixin custom-control($state, $color) {
  border-color: $color;
  box-shadow: inset 0 (-$sgbs-one-px-equiv) 0 0 $color;
  ~ .#{$state}-feedback {
    display: flex;
  }
  ~ .#{$state}-tooltip {
    display: block;
  }

  &:disabled,
  &.disabled,
  &[readonly],
  &.readonly {
    border-color: $color;
    box-shadow: inset 0 (-$sgbs-one-px-equiv) 0 0 $color;
  }

  &:not(:disabled):not(.disabled):not([readonly]):not(.readonly) {
    &:hover {
      box-shadow: inset 0 0 0 $sgbs-one-px-equiv $color;
    }

    &:focus {
      box-shadow: inset 0 0 0 2px $color; // cf. $input-focus-box-shadow in _variables.scss
    }
  }
}

@mixin form-validation-state($state, $color, $icon: '') {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    font-size: $form-feedback-font-size;
    color: $color;
    align-items: center;

    .icon {
      border: 1px solid currentColor;
      border-radius: 50%;
      width: 0.7rem;
      height: 0.7rem;
      margin-right: 3px;
      line-height: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
    }
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    width: 250px;
    padding: $tooltip-padding-y $tooltip-padding-x;
    margin-top: 0.1rem;
    font-size: $tooltip-font-size;
    line-height: 1;
    color: color-yiq($color);
    background-color: rgba($color, $tooltip-opacity);
    @include border-radius($tooltip-border-radius);
  }

  .form-control,
  .custom-select {
    &.is-#{$state} {
      @include custom-control($state, $color);
    }
  }

  .form-control,
  .custom-select {
    .was-validated &:#{$state} {
      @include custom-control($state, $color);
    }
  }

  // is-* control inside was-validated form exception
  .was-validated .form-control.is-#{$state} {
    @include custom-control($state, $color);
  }

  .form-check-input {
    &.is-#{$state} {
      ~ .form-check-label {
        color: $color;
      }
    }
  }

  .custom-control-input {
    &.is-#{$state} {
      ~ .custom-control-label {
        color: $color;

        &::before {
          background-color: lighten($color, 25%);
        }
      }

      ~ .#{$state}-feedback {
        display: flex;
      }
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:checked {
        ~ .custom-control-label::before {
          @include gradient-bg(lighten($color, 10%));
        }
      }

      &:focus {
        ~ .custom-control-label::before {
          box-shadow: 0 0 0 1px $body-bg,
            0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }

  // custom file
  .custom-file-input {
    &.is-#{$state} {
      ~ .custom-file-label {
        border-color: $color;

        &::before {
          border-color: inherit;
        }
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }

      &:focus {
        ~ .custom-file-label {
          box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }
}

@mixin sgbs-custom-checkbox-variant($color) {
  background-image: str-replace(
    url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='3 3 18 18' fill='#{$color}'%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3cpath d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'/%3e%3c/svg%3e"),
    '#',
    '%23'
  );
}

@mixin sgbs-custom-checkbox-indeterminate-variant($color) {
  background-image: str-replace(
    url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='16' height='16' viewBox='3 3 18 18' fill='#{$color}'%3e%3cdefs%3e%3cpath id='a' d='M0 0h24v24H0z'/%3e%3c/defs%3e%3cclipPath id='b'%3e%3cuse xlink:href='%23a' overflow='visible'/%3e%3c/clipPath%3e%3cpath clip-path='url(%23b)' d='M21 3H3v18h18V3zm-4 10H7v-2h10v2z'/%3e%3c/svg%3e"),
    '#',
    '%23'
  );
}

@mixin sgbs-custom-radio-variant($color) {
  background-image: str-replace(
    url("data:image/svg+xml;charset=UTF-8, %3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='2 2 20 20' fill='#{$color}'%3e%3cpath d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/%3e%3cpath d='M0 0h24v24H0z' fill='none'/%3e%3c/svg%3e"),
    '#',
    '%23'
  );
}
