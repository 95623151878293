//
// Base styles
//

@import 'bootstrap/scss/tables';

.table {
  thead th {
    color: $sgbs-table-header-color;
    border-bottom-color: $sgbs-table-header-border-bottom-color;
    border-bottom-width: $sgbs-table-header-border-bottom-width;
    border-top-color: $sgbs-table-border-top-color;
    @include variable-weight($font-weight-medium);
  }

  &.table-dark thead th {
    border-bottom-color: $sgbs-table-header-border-alt-bottom-color;
  }
}

// Hover effect
//
.table-hover {
  tbody tr {
    @include hover {
      background: $table-bg; // don't change the background

      td,
      th {
        // Ensure that the existing top border of td and th isn't covering the
        // shadow.
        border-color: transparent;
      }
    }

    &.active,
    &:active {
      box-shadow: none;
      td,
      th {
        background: $table-active-bg;
      }
    }
  }

  &.table-bordered {
    tbody tr {
      @include hover {
        td,
        th {
          // Because table has border-collapsed we need to give the hovered row the ability
          // to visually override its siblings, in orer to display its border when hovered
          //
          // https://stackoverflow.com/questions/4096390/table-border-color-in-css-with-border-collapse
          // The rules that have higher precedence is
          // wider border wins over narrower ones
          // BUT if we change border width the table would "shake"
          // and after that, double wins over solid, then dashed, dotted, ridge, outset, groove, inset
          border-style: double;
        }
      }
    }
  }
}

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color));

  .table-hover-#{$color} {
    tbody tr {
      @include hover() {
        color: $value;
        background-color: rgba($value, map-get($sgbs-opacities, 'md'));
      }
    }
  }
}
