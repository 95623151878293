//
// STATES
//

// Disabled
@mixin sgbs-disabled-button($color) {
  &.disabled,
  &:disabled {
    background-color: $color;
    border-color: $color;
    box-shadow: none;
  }
}

// Active
@mixin sgbs-active-button($color) {
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    color: transparentize($primary-alt, 0.25);
    background-color: $color;
    border-color: $color;
  }
}

// Drastic hover
@mixin sgbs-drastic-hover-contrast($color, $hover-color) {
  @include hover-focus {
    &:not([disabled]):not(.disabled) {
      color: $primary-alt;
      background-color: $hover-color;
      border-color: $hover-color;
    }
  }
}

//
// STYLES
//

// Color variant
@mixin sgbs-button-variant($color, $hover-color) {
  color: $primary-alt;
  background-color: $color;
  border-color: $color;

  @include sgbs-drastic-hover-contrast($color, $hover-color);
  @include sgbs-disabled-button($color);
  @include sgbs-active-button($color);
}

// Outline
@mixin sgbs-button-outline-variant($color) {
  background-color: transparent;
  border-color: $color;
  color: $color;

  &:hover {
    background-color: $color;
    color: $sgbs-button-hover-color;
  }

  &:focus,
  &.focus {
    background-color: $color;
    color: $sgbs-button-focus-color;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $sgbs-button-active-color;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
    border-color: $color;
    box-shadow: none;
  }
}

// additional button style for toggle buttons
// this is a sg-bootstrap special
//
@mixin sgbs-button-toggle-variant ($color, $hover-color) {
  color: $color;
  background-color: transparent;
  border-color: $color;

  &:hover {
    background-color: rgba($color, map-get($sgbs-opacities, 'lg'));
  }

  // No effect on disabled buttons
  &:not([disabled]):not(.disabled):active {
    background-color: rgba($color, 0.3);
  }

  &.disabled,
  &:disabled {
    background-color: transparent;
    color: $color;
  }

  &.active {
    color: $primary-alt;
    background-color: $color;

    &:hover,
    &:focus,
    &.focus {
      background-color: $hover-color;
      border-color: $hover-color;
    }

    &:not([disabled]):not(.disabled):active {
      color: transparentize(color-yiq($color), 0.25);
      background-color: $color;
      border-color: $color;
    }
  }
}

// Flat
@mixin sgbs-button-flat-variant($color) {
  color: $color;
  background-color: transparent;

  @include hover {
    text-decoration: none;
    background-color: rgba($color, map-get($sgbs-opacities, 'md'));
  }

  &:focus,
  &.focus {
    background-color: rgba($color, map-get($sgbs-opacities, 'md'));
    box-shadow: none;
  }

  &:not([disabled]):not(.disabled).active,
  &:not([disabled]):not(.disabled):active {
    background-color: rgba($color, map-get($sgbs-opacities, 'lg'));
  }

  // Ensure that hover effect don't affect the default disabled state
  // Unchanged from bootstrap@4.0.0
  // see https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L100-L103
  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

// Discreet
@mixin sgbs-button-discreet-variant($color) {
  color: $color;
  background-color: rgba($color, $sgbs-discreet-button-gradient);

  @include sgbs-drastic-hover-contrast($color, $color);
  @include sgbs-active-button($color);

  // Ensure that hover effect don't affect the default disabled state
  // Unchanged from bootstrap@4.0.0
  // see https://github.com/twbs/bootstrap/blob/v4.0.0/scss/_buttons.scss#L100-L103
  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin btn-sizing($padding-y, $padding-x, $icon-size) {
  // excluded [data-toggle='collapse'] to prevent breaking accordion arrow
  // introduced class .sgbs-square-collapse to specifically create a square
  // collapse toggler made of an icon inside a [data-toggle='collapse'] button
  &:not([data-toggle='collapse']) > .icon,
  &.sgbs-square-collapse > .icon {
    margin-left: $padding-y - $padding-x;
    margin-right: $padding-y - $padding-x;
    font-size: $icon-size;
    line-height: 1;
  }

  &.btn-icon-text {
    > .icon {
      margin-top: -1 * $btn-border-width;
      margin-right: ($padding-x - $padding-y) / 2;
    }
  }

  &.btn-text-icon {
    > .icon {
      margin-top: -1 * $btn-border-width;
      margin-left: ($padding-x - $padding-y) / 2;
    }
  }
}

@mixin button-in-card-states($color) {
  &:hover:not(:disabled):not(.disabled) {
    color: $primary-alt;
    background-color: button-hover-background($color);
    border-color: button-hover-background($color);
  }

  &:focus,
  &.focus {
    color: $primary-alt;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: button-active-color($primary-alt);
    background-color: $color;
    border-color: $color;
  }
}
