// Styles that need to be defined before
// bootstrap rules because they would override
// some rules they should not touch.
// This should be the exception !

.form-control {
  &:hover {
    box-shadow: $sgbs-input-hover-box-shadow;
  }
}
