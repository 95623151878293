//
// Base styles
//

@import 'bootstrap/scss/reboot';

body {
  font-family: $font-family;
}

//
// Links
//

a {
  &:not(.text-link) {
    @include variable-weight($sgbs-link-font-weight);
  }

  &.text-link {
    color: $primary;
    text-decoration: none;

    // inspired by: https://css-tricks.com/almanac/properties/t/text-underline-position/
    // support: https://caniuse.com/#search=text-underline-position
    text-underline-position: under;
    text-decoration: underline;

    &:hover:not(:disabled):not(.disabled) {
      background-color: rgba($primary, 0.08);
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background-color: rgba($primary, 0.15);
    }
  }
}
