.table-form {
  thead th {
    padding-left: $input-padding-x;
  }

  tbody {
    td {
      padding: 0;
    }

    th {
      vertical-align: middle;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  td {
    button.focus,
    button:focus {
      outline: none;
      box-shadow: #{$btn-focus-box-shadow} inset;
    }
  }

  .form-control {
    background: transparent;

    &.disabled,
    &:disabled,
    &.readonly,
    &[readonly] {
      box-shadow: none;
    }
  }

  &.table-sm {
    thead {
      th {
        padding: 0 $input-btn-padding-x-sm;
        line-height: $input-btn-line-height-sm;
        font-size: $sgbs-btn-font-size-sm;
      }
    }
  }

  .input-group {
    flex-wrap: nowrap;
  }
}
