//
// Base styles
//

@import 'bootstrap/scss/badge';

.badge {
  align-items: center;
  justify-content: flex-start;
  border: $btn-border-width solid transparent;
  color: $sgbs-badge-color;
  @include variable-weight($badge-font-weight);
}

//
// If you are using it on a link
//

a[href].badge {
  // No underline style
  @include hover-focus {
    text-decoration: none;
    color: $primary-alt;
  }
}

//
// Pill badges
//
.badge-pill {
  border-radius: $badge-pill-border-radius;
}

//
// Icon in badges
//

// The square case
.badge-prepend-square {
  position: relative;
  padding-left: $badge-padding-x * 2 + $sgbs-badge-square-size;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: $badge-padding-x;
    margin: auto;
    width: $sgbs-badge-square-size;
    height: $sgbs-badge-square-size;
    background: currentColor;
  }
}

// Preprend icons case
.badge-prepend-icon {
  $size: 1.05em;
  width: $size;
  height: $size;
  margin: -2px;
  margin-right: 0.05em;
}

// Solo icons case
.badge-icon {
  $size: 1.05em;
  width: $size;
  height: $size;
  margin: $size / -2;
}

//
// Badge Sizes
//

.badge-sm {
  @include sgbs-badge-size(
    $sgbs-badge-height-sm,
    $sgbs-badge-font-size-sm,
    $sgbs-badge-square-size-sm
  );
}

.badge-lg {
  @include sgbs-badge-size(
    $sgbs-badge-height-lg,
    $sgbs-badge-font-size-lg,
    $sgbs-badge-square-size-lg
  );
}

.badge-xl {
  @include sgbs-badge-size(
    $sgbs-badge-height-xl,
    $sgbs-badge-font-size-xl,
    $sgbs-badge-square-size-xl
  );
}

//
//
//

// Colored variants
@mixin generate-palette-badges($colors) {
  @each $color, $value in $colors {
    .badge-#{$color} {
      background-color: $value;
      border-color: $value;

      &.badge-primary-alt {
        color: $primary;
      }

      // Only the solid badge have a transparent square
      &.badge-prepend-square::before {
        opacity: $sgbs-badge-square-opacity;
      }
    }

    .badge-outline-#{$color} {
      background-color: transparent;
      border-color: $value;
      color: #{$value};

      // &.badge-outline-primary-alt {
      //   border-color: $primary;
      //   color: $primary;
      // }
    }

    .badge-discreet-#{$color} {
      color: $value;
      background-color: rgba($value, $sgbs-discreet-button-gradient);
    }

    .badge-flat-#{$color} {
      color: $value;
      background-color: transparent;
    }
  }
}

// adapted badges
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .badge.badge-#{$name} {
      background-color: $adapted_color;
      border-color: $adapted_color;
    }
  }
}

// Badges over dark backgrounds
@each $background in $sgbs-adaptive-backgrounds-dark {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .badge.badge-#{$name} {
      color: #000;
    }
  }
}

// Badges over light backgrounds
@each $background in $sgbs-adaptive-backgrounds-light {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    .#{$background} .badge.badge-#{$name} {
      color: $white;
    }
  }
}

@include generate-palette-badges($theme-colors);
@include generate-palette-badges($sgbs-secondary-colors);

.sgbs-badge-default {
  border: $border-width solid $sgbs-badge-default-border-color;
  background: transparent;
  color: $primary;

  &.badge-prepend-square::before {
    opacity: $sgbs-badge-square-opacity;
  }
}

//
// OUTLINE
//

// Outline button adapted
@each $background in $sgbs-adaptive-backgrounds {
  @each $name, $color in map-get($sgbs-adaptive-palette, $background) {
    $adapted_color: map-get(
      map-get($sgbs-adaptive-palette, $background),
      $name
    );
    .#{$background} .badge.badge-outline-#{$name} {
      border-color: $adapted_color;
      color: $adapted_color;
    }
  }
}
