//
// Base styles
//

@import 'bootstrap/scss/utilities/flex';

//
// Extra flex utility classes
// Inspired by https://github.com/twbs/bootstrap/blob/v4.1.0/scss/utilities/_flex.scss
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .flex-basis#{$infix}-#{100} {
      flex-basis: 100% !important;
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-between {
  display: flex;
  justify-content: space-between;

  &.flex-column {
    justify-content: center;
    align-items: space-between;
  }
  align-items: center;
}
